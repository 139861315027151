<template>
    <div class="auth-wrapper auth-v1"
         style="padding-top: 0;padding-bottom: 0;">
        <div class="auth-inner main-container-width">
            <v-row v-if="loaded">
                <v-col cols="12"
                       sm="12"
                       md="12"
                       lg="12"
                       class="mx-auto">
                    <v-card flat
                            class="bg-gradient">
                        <app-header :details="cardDetails"></app-header>
                        <v-row class="mx-lg-5 mx-md-2 mx-sm-3 mx-3 mt-5">
                            <v-col cols="12"
                                   lg="3"
                                   md="12"
                                   sm="12"
                                   style="height: 192px;padding-top: 3px;margin-top: 12px;"
                                   class="balance-card-background">
                                <div class="mx-auto"
                                     v-if="loadCards"
                                     style="max-width: 250px;">
                                    <Splide :options="options"
                                            @splide:moved="onAfterSlideChange"
                                            class="ar-splide"
                                            aria-label="Vue Splide Example">
                                        <SplideSlide v-for="(card, i) in primaryCards"
                                                     :key="i"
                                                     :index="i">
                                            <v-img contain
                                                   height="180px"
                                                   class="auth-3d-group overflow-hidden"
                                                   style="margin: 5px;"
                                                   :src="card.image">
                                            </v-img>
                                        </SplideSlide>

                                    </Splide>
                                </div>

                            </v-col>
                            <v-col cols="12"
                                   lg="9"
                                   md="12"
                                   sm="12"
                                   class="card-balance">
                                <div>
                                    <CardBalanceRedesign @details="onDetails"
                                                         :cardDetails="cardDetails"></CardBalanceRedesign>
                                </div>
                                <div class="card-background pt-3">
                                    <Reward :card="cardDetails"
                                            :refresh="refreshPoints"
                                            :rewards="membershipRewards"></Reward>
                                </div>

                                <div v-if="payment && screenWidth > 1200 && cardDetails.amountDue.amt > 0"
                                     class="balance-card-background px-lg-5 px-md-2 px-sm-2 px-1 mt-0 d-lg-flex d-block mt-3"
                                     style="padding: 17px 16px; border-radius: 5px;">

                                    <div class="d-flex align-center">
                                        <span class="text-center"
                                              style="width: 32px;height: 32px;">
                                            <v-img contain
                                                   class="auth-3d-group overflow-hidden mx-auto mb-2"
                                                   :src="require(`@/assets/images/icons/payment-due.png`)">
                                            </v-img>
                                        </span>

                                        <span class="text-1xl font-weight-semibold text-color-black mx-2"
                                              style="font-size: 12px;">
                                            {{ $t('Payment Due') }}:
                                            <span v-if="selectedLanguage == 'en'">
                                                {{ $t(cardDetails.amountDue.cur) }}
                                            </span>
                                            {{ cardDetails.amountDue.amt | VMask(currencyMask) }}
                                            <span v-if="selectedLanguage == 'ar'">
                                                {{ $t(cardDetails.amountDue.cur) }}
                                            </span>
                                        </span>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <div>
                                        <span class="text-1xl font-weight-semibold mx-2"
                                              style="font-size: 11px;">
                                            {{ $t('Your Sadad number is') }}: {{ cardDetails.sadadNumber }}
                                        </span>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <div class="d-flex align-center"
                                         style="margin-bottom: 3px;">
                                        <span class="warning2 d-flex">
                                            <span class="text-center"
                                                  style="width: 16px;height: 16px;">
                                                <v-img contain
                                                       class="auth-3d-group overflow-hidden mx-auto"
                                                       :src="require(`@/assets/images/icons/time.png`)">
                                                </v-img>
                                            </span>


                                            <span class="text-1xl font-weight-semibold mx-1 text-color-black"
                                                  style="font-size: 12px;color: black;">
                                                &nbsp;&nbsp;{{ $t('Due on') }} {{ getArabicDate(cardDetails.nextDueDate,
                                                    selectedLanguage, true) }}
                                            </span>
                                        </span>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <div>
                                        <span @click="payBils = true">
                                            <span class="text-1xl font-weight-semibold mx-2"
                                                  style="font-size: 11px;color: #006FCF;">
                                                {{ $t('How do I pay my bills?') }}
                                            </span>
                                        </span>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <div>
                                        <v-img contain
                                               @click="payment = false"
                                               style="cursor: pointer"
                                               class="circle auth-3d-group overflow-hidden mx-auto"
                                               :src="require(`@/assets/images/icons/cross-circle.png`)">
                                        </v-img>
                                    </div>
                                </div>

                                <v-card class="mt-3 balance-card-background"
                                        v-if="payment && screenWidth < 1200 && cardDetails.amountDue.amt > 0">
                                    <v-card-text class="d-flex card-paymentdue">
                                        <div class="payment-due">
                                            <v-img contain
                                                   class="ml-1 auth-3d-group overflow-hidden alert"
                                                   :src="require(`@/assets/images/icons/payment-due.png`)">
                                            </v-img>
                                        </div>

                                        <div class="ml-2">
                                            <span style="font-size: 12px;"
                                                  class="text-black font-weight-semibold">
                                                {{ $t('Payment Due') }}:

                                                <span v-if="selectedLanguage == 'en'">
                                                    {{ $t(cardDetails.amountDue.cur) }}
                                                </span>
                                                {{ cardDetails.amountDue.amt | VMask(currencyMask) }}
                                                <span v-if="selectedLanguage == 'ar'">
                                                    {{ $t(cardDetails.amountDue.cur) }}
                                                </span>
                                            </span>
                                            <p style="font-size: 11px;">
                                                {{ $t('Your Sadad number is') }}: {{ cardDetails.sadadNumber }}
                                            </p>

                                            <div style="font-size: 12px;"
                                                 class="pa-0">
                                                {{ $t('Due date:') }} {{ getArabicDate(cardDetails.nextDueDate,
                                                    selectedLanguage, true) }}
                                            </div>
                                            <div class="text-blue"
                                                 @click="payBils = true"
                                                 style="font-size: 12px;">
                                                {{ $t('How do I pay my bills?') }}
                                            </div>
                                        </div>

                                        <div class="cross">
                                            <v-img @click="payment = false"
                                                   contain
                                                   style="height: 16px;cursor: pointer"
                                                   class="auth-3d-group overflow-hidden mt-0"
                                                   :src="require(`@/assets/images/icons/cross-circle.png`)">
                                            </v-img>
                                        </div>

                                    </v-card-text>
                                </v-card>
                                <OffersHome></OffersHome>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog v-model="payBils"
                      width="60%"
                      style="overflow: hidden;">
                <v-card class="public-contact">
                    <v-card-title>
                        <span class="text-h5 text-color-black">
                            {{ $t(`How do I pay my bills?`) }}
                        </span>
                        <v-spacer></v-spacer>
                        <span style="cursor: pointer"
                              @click="payBils = false"
                              class="arabic-close en-close">
                            <v-icon>
                                {{ icons.mdiClose }}
                            </v-icon>
                        </span>
                    </v-card-title>

                    <v-card-text>

                        <p style="font-size: 12px;"
                           v-html="getFaqs('pay_bils_ans1')">
                        </p>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="promotional"
                      persistent>
                <v-card class="v-dialog-logout"
                        v-if="!prefsAdded">
                    <v-card-text>
                        <div class="text-center">
                            <div class="secure-img"
                                 style="background: transparent;height: 80px">
                                <img style="height: 80px;"
                                     src="@/assets/images/logos/promotional.png"
                                     alt="">
                            </div>
                            <p class="mt-8 font-weight-bold text-lg">{{ $t('Marketing Communication') }}</p>
                            <p class="mt-3 text-md">
                                {{ $t(`promoDialog`) }}
                            </p>
                        </div>
                    </v-card-text>
                    <v-card-text>
                        <v-row class="mx-10">
                            <v-col cols="12"
                                   class="pa-0">
                                <div class="d-flex align-center"
                                     style="height: 54px; border: 1px solid rgba(209, 209, 209, 1);border-radius: 10px;">
                                    <div>
                                        <img class="mx-3"
                                             src="@/assets/images/logos/selectAllCheck.png"
                                             style="max-width: 27px;max-height: 27px;margin-top: 5px;">
                                    </div>
                                    <div style="width: 100%;"
                                         class="font-weight-bold mx-2">{{ $t('Select All') }}</div>
                                    <div style="width: 40px;"
                                         class="font-weight-bold mx-2">
                                        <v-checkbox @change="onSelectAllCheck()"
                                                    v-model="selectAllCheck"></v-checkbox>
                                    </div>
                                </div>
                            </v-col>

                            <v-col cols="12"
                                   class="pa-0">
                                <div class="d-flex align-center"
                                     style="height: 54px;background: rgba(247, 247, 247, 1);margin-top:5px; border-radius:10px;">
                                    <div>
                                        <img class="mx-3"
                                             src="@/assets/images/logos/emailCheck.png"
                                             style="max-width: 27px;max-height: 27px;margin-top: 5px;">
                                    </div>
                                    <div style="width: 100%;"
                                         class="font-weight-bold mx-2">{{ $t('Email') }}</div>
                                    <div style="width: 40px;"
                                         class="font-weight-bold mx-2">
                                        <v-checkbox @change="optionsCheck()"
                                                    v-model="emailCheck"></v-checkbox>
                                    </div>
                                </div>
                            </v-col>

                            <v-col cols="12"
                                   class="pa-0">
                                <div class="d-flex align-center"
                                     style="height: 54px;background: rgba(247, 247, 247, 1);margin-top:5px; border-radius:10px;">
                                    <div>
                                        <img class="mx-3"
                                             src="@/assets/images/logos/smsCheck.png"
                                             style="max-width: 27px;max-height: 27px;margin-top: 5px;">
                                    </div>
                                    <div style="width: 100%;"
                                         class="font-weight-bold mx-2">{{ $t('SMS') }}</div>
                                    <div style="width: 40px;"
                                         class="font-weight-bold mx-2">
                                        <v-checkbox @change="optionsCheck()"
                                                    v-model="smsCheck"></v-checkbox>
                                    </div>
                                </div>
                            </v-col>

                            <v-col cols="12"
                                   class="pa-0">
                                <div class="d-flex align-center"
                                     style="height: 54px;background: rgba(247, 247, 247, 1);margin-top:5px; border-radius:10px;">
                                    <div>
                                        <img class="mx-3"
                                             src="@/assets/images/logos/phoneCheck.png"
                                             style="max-width: 27px;max-height: 27px;margin-top: 5px;">
                                    </div>
                                    <div style="width: 100%;"
                                         class="font-weight-bold mx-2">{{ $t('Phone') }}</div>
                                    <div style="width: 40px;"
                                         class="font-weight-bold mx-2">
                                        <v-checkbox @change="optionsCheck()"
                                                    v-model="phoneCheck"></v-checkbox>
                                    </div>
                                </div>
                            </v-col>

                            <v-col cols="12"
                                   class="pa-0 mt-7">
                                <div class="d-flex align-center"
                                     style="height: 54px;border: 1px solid rgba(209, 209, 209, 1);border-radius: 10px; margin-top: 5px">
                                    <div>
                                        <img class="mx-3"
                                             src="@/assets/images/logos/noneCheck.png"
                                             style="max-width: 27px;max-height: 27px;margin-top: 5px;">
                                    </div>
                                    <div style="width: 100%;"
                                         class="font-weight-bold mx-2">{{ $t('None') }}</div>
                                    <div style="width: 40px;"
                                         class="font-weight-bold mx-2">
                                        <v-checkbox @change="onNoneCheck()"
                                                    v-model="noneCheck"></v-checkbox>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <div class="text-center"
                         v-if="noneCheck">
                        <p class="mt-3 text-md px-5"
                           style="font-size: 14px">
                            {{ $t('noneSelectedFirst') }}
                            <b>{{ $t('NoneTwo') }}</b>
                            {{ $t('noneSelectedSecond') }}
                        </p>
                    </div>
                    <div class="pb-5 text-center mt-3">
                        <v-btn @click="saveUserPrefs"
                               color="primary"
                               type="submit"
                               large>
                            {{ $t(`Save`) }}
                        </v-btn>
                    </div>
                </v-card>
                <v-card class="v-dialog-logout"
                        v-if="prefsAdded">
                    <v-card-text class="px-10">
                        <div class="text-center">
                            <div class="secure-img"
                                 style="background: transparent;height: 80px">
                                <img style="height: 80px;"
                                     src="@/assets/images/logos/tick-prefs.png"
                                     alt="">
                            </div>
                            <p class="mt-8 font-weight-bold text-lg px-10">
                                {{ $t('prefsAddedThank') }} <br>
                                {{ $t('prefsAdded') }}
                            </p>
                            <p class="mt-3 text-md px-10">
                                {{ $t(`prefsAddedDes`) }} <br>
                                <b>{{ $t(`prefsAddedDesTwo`) }}</b>
                            </p>
                        </div>
                    </v-card-text>
                    <div class="pb-5 text-center mt-3">
                        <v-btn @click="promotional = false"
                               color="primary"
                               type="submit"
                               large>
                            {{ $t(`Done`) }}
                        </v-btn>
                    </div>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'

import sharedServices from '@/services/shared-services'
import homeServices from '@/services/home-services'
import soapServices from '@/services/soap-services'
import transactionServices from '@/services/transaction-services'
import router from '@/router'
import FAQs from '@/@core/utils/FAQs'
import moment from 'moment'
import { DeviceUUID } from 'device-uuid'
import AppHeader from '../test/AppHeader.vue'
import CardBalanceRedesign from '../test/CardBalanceRedesign.vue'
import Reward from '../test/Reward'
import valueWraper from '../../@core/utils/keyValueMaper';
import newProductCode from '@/@core/utils/newProductCode'
import store from '@/store'
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { mdiClose } from '@mdi/js'

import CardStatusGeneral from '@/@core/utils/CardStatusGeneral';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import arabicDate from '@/@core/utils/arabicDate';

import OffersHome from './OffersHome.vue';

import { getAnalytics, logEvent } from "firebase/analytics";

const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
});
export default {
    components: {
        Carousel3d,
        Slide,
        CardBalanceRedesign,
        AppHeader,
        Reward,
        Splide,
        SplideSlide,
        OffersHome
    },
    data: () => ({
        selectAllCheck: true,
        phoneCheck: true,
        emailCheck: true,
        smsCheck: true,
        noneCheck: false,
        prefsAdded: false,

        promotional: false,
        analytics: getAnalytics(),
        currencyMask,
        accounts: [],
        primaryCards: [],
        allCards: [],
        screenWidth: '',
        isActive: [],
        inActive: [],
        payBils: false,
        accountCards: [],
        cardPlan: {},
        loadCards: false,
        payment: true,
        icons: {

            mdiClose
        },
        dir: 'ltr',
        selectedCardIndex: 0,
        cardDetails: {
            accountCreditRiskLimit: { amt: '', cur: '' },
            creditLimit: { amt: '', cur: '' },
            totalRecentPayments: { amt: '', cur: '' },
            totalRecentsTransaction: { amt: '', cur: '' },
            previousBalance: { amt: '', cur: '' },
            cardLimit: { amt: '', cur: '' },
            availableCredit: { amt: '', cur: '' },
            currentBalance: { amt: '', cur: '' },
            amountDue: { amt: '', cur: '' },
        },
        options: {
            type: 'slide',
            padding: '1.5rem',
            arrows: true,
            direction: 'ltr',
            arrowPath: '',
            start: 0,
            classes: {
                arrows: 'splide__arrows your-class-arrows',
                arrow: 'splide__arrow your-class-arrow',
                prev: 'splide__arrow--prev your-class-prev',
                next: 'splide__arrow--next your-class-next',
            },
            // direction: 'rtl',
            // paginationDirection: 'ltr',
        },
        firstLoad: true,
        membershipRewards: '',
        loyaltyAccountNbr: 0,
        loaded: false,
        selectedLanguage: '',
        refreshPoints: 0,
    }),
    mounted()
    {
        this.getEntities();
        window.scrollTo(0, 0);
        this.screenWidth = screen.width;
        store.dispatch('loader/show');
    },
    created()
    {
        store.subscribe((mutation) =>
        {

            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.loadCards = false;
                this.selectedLanguage = mutation.payload;
                if (this.selectedLanguage == 'ar')
                {
                    this.options.direction = 'rtl';
                    this.options.start = this.selectedCardIndex;
                }
                else
                {
                    this.options.direction = 'ltr';
                    this.options.start = this.selectedCardIndex;
                }
                setTimeout(() =>
                {
                    this.loadCards = true;
                }, 1000);
            }
        })
    },
    methods: {
        onSelectAllCheck()
        {
            this.phoneCheck = true;
            this.emailCheck = true;
            this.smsCheck = true;
            this.noneCheck = false;
        },
        onNoneCheck()
        {
            this.phoneCheck = false;
            this.emailCheck = false;
            this.smsCheck = false;
            this.selectAllCheck = false;
        },
        optionsCheck()
        {
            if (this.phoneCheck && this.emailCheck && this.smsCheck)
            {
                this.selectAllCheck = true;
            }
            else if (!this.phoneCheck && !this.emailCheck && !this.smsCheck)
            {
                this.noneCheck = true;
            }
            else
            {
                this.noneCheck = false;
                this.selectAllCheck = false;
            }
        },
        saveUserPrefs()
        {
            let data = {
                userLanguage: this.selectedLanguage == 'ar' ? 'arabic' : 'english',
                promotionalSMS: this.smsCheck == false ? '0' : '1',
                promotionalEmail: this.emailCheck == false ? '0' : '1',
                promotionalCalls: this.phoneCheck == false ? '0' : '1'
            }
            sharedServices.addUserPrefers(data).then(prefs =>
            {
                if (prefs.status == 200)
                {
                    this.prefsAdded = true;
                }
                // else
                // {
                //     this.alertMessage = 'Error'
                //     this.alertType = 'error'
                //     this.showAlert = true
                //     setTimeout(() =>
                //     {
                //         this.showAlert = false
                //     }, 5000)
                // }
                // promotionalCalls: "1"
                // promotionalEmail: "0"
                // promotionalSMS: "1"
                // userLanguage: "english"
            });
        },
        getArabicDate(dateTime, lang, year)
        {
            return arabicDate.get(dateTime, lang, year)
        },
        onDetails()
        {
            logEvent(this.analytics, 'AMEX_CI_CARD_DETAILS', {
                content_type: 'userName',
                content_id: store.get('requestKeys/userName')
            })
            let data = [];
            let cardDetails = store.get('card/details');
            let primaryCards = store.get('cardStore/accountCards');
            for (let index = 0; index < primaryCards.length; index++)
            {
                primaryCards[index].forEach(element =>
                {
                    if (cardDetails.image == element.image)
                    {
                        data.push(element);
                    }
                });
            }
            store.set('cardStore/selectedAccount', data);
            transactionServices.getPendingTransactions().then(response =>
            {
                store.set('card/pendingTransaction', response);
            })
            router.push('/card-detail');
        },
        getFaqs(name)
        {
            return FAQs.getFaqs(this.selectedLanguage, name)
        },
        onAfterSlideChange(index)
        {
            this.refreshPoints++;

            let primaryCard = this.primaryCards[index.index];
            this.selectedCardIndex = index.index;

            store.set('card/details', primaryCard);

            this.cardDetails = primaryCard;

            // console.log('primaryCard.loyaltyAccountNbr', primaryCard.loyaltyAccountNbr);


            // if (this.loyaltyAccountNbr != primaryCard.loyaltyAccountNbr && this.loyaltyAccountNbr != 0)
            // {
            if (primaryCard.loyaltyAccountNbr)
            {
                this.membershipRewardPoints(index.index);
            }
            else
            {
                // this.membershipRewards = primaryCard.loyaltyAccountNbr.toString();
            }
            // }
        },
        getEntities()
        {
            homeServices.getEntities(store.get('requestKeys/userName')).then(resp => // username from store
            {
                if (resp.data.responseInfo.errorCode == '00000')
                {
                    let name = resp.data.customer[0].customerDetail.clientDemog.firstName + ' ' + resp.data.customer[0].customerDetail.clientDemog.familyName;
                    store.set('accounts/name', name);

                    let lastLogin = valueWraper.getLastLogin(resp.data.customer[0].customerDetail.clientCustomFields);
                    store.set('accounts/lastLogin', lastLogin);
                    store.set('accounts/clientDetail', resp.data.customer[0].customerDetail);

                    let customerDetail = resp.data.customer[0].customerDetail;

                    customerDetail.keyValues.forEach(element =>
                    {
                        if (element.key == 'PROMOTIONAL_CONSENT' && element.data == '0')
                        {
                            // console.log('Open Dialog');
                            this.promotional = true;
                        }
                    });

                    this.accounts = resp.data.customer[0].account

                    this.accounts.forEach(account =>
                    {
                        let mRAccountStatus = '';
                        let mrAccountNo = '';

                        let num = 0
                        if (account.loyaltyAccounts.accountLoyalty)
                        {
                            if (account.loyaltyAccounts.accountLoyalty.length > 0)
                            {
                                num = account.loyaltyAccounts.accountLoyalty[0].loyaltyAccountNbr;
                                if (num != 0)
                                {
                                    this.loyaltyAccountNbr = num;
                                }
                            }

                            mRAccountStatus = valueWraper.getMrAccountStatus(account.loyaltyAccounts.accountLoyalty);
                            mrAccountNo = valueWraper.getMrAccountNo(account.loyaltyAccounts.accountLoyalty);
                        }

                        let tempCards = [];

                        account.card.forEach(card =>
                        {
                            let last4Digits = valueWraper.getCardNo(card.cardDetail.cardDemog.keyValues);
                            let productType = valueWraper.getAccountProductType(account.accountBalance.keyValues);
                            let productCode = valueWraper.getAccountProductCode(account.accountBalance.keyValues);
                            let newCode = newProductCode.getNewProductCode(productCode);
                            productCode = newCode;

                            let productName = valueWraper.getAccountProductName(productCode, 'en');
                            let productNameAr = valueWraper.getAccountProductName(productCode, 'ar');
                            let primeProductName = valueWraper.getPrimeProductName(productCode);
                            let cardStgeneral = (card.cardDetail?.cardDemog?.statusCode ?? '') +
                                (card.cardDetail?.cardDemog?.statusReason ?? '');

                            let cardActivationStatus = valueWraper.getCardActivationStatus(account.accountBalance.keyValues, cardStgeneral);
                            let sadadNumber = valueWraper.getSadadNumber(account.accountBalance.keyValues);

                            if (!newCode)
                            {
                                newCode = 'nmc';
                            };


                            let image = '';

                            try
                            {
                                image = require('@/assets/images/cards/' + newCode.toLowerCase() + '.png')
                            }
                            catch (error)
                            {
                                console.log(error);
                            }

                            let accountStgeneral = valueWraper.getAccountStGeneral(
                                account.accountBalance?.keyValues ?? [],
                            );

                            let accountCreditRiskLimit = {
                                amt: valueWraper.divideByExponents(
                                    account.accountBalance?.crdAcctInfo?.creditLimit,
                                    account.accountBalance?.currentBalance?.curCode
                                ),
                                cur: valueWraper.getCurrency(account.accountBalance?.currentBalance?.curCode)
                            }

                            let creditLimit = {
                                amt: valueWraper.divideByExponents(
                                    account.accountBalance?.crdAcctInfo?.creditLimit,
                                    'noDivide'
                                ),
                                cur: valueWraper.getCurrency(account.accountBalance?.currentBalance?.curCode)
                            }

                            let totalRecentPayments = {
                                amt: valueWraper.divideByExponents(
                                    account.accountBalance?.currentCredit?.amt,
                                    account.accountBalance?.currentCredit?.curCode
                                ),
                                cur: valueWraper.getCurrency(account.accountBalance?.currentCredit?.curCode)
                            }

                            let totalRecentsTransaction = {
                                amt: valueWraper.divideByExponents(
                                    (account.accountBalance?.currentBalance?.amt ?? 0) -
                                    (account.accountBalance?.currentCredit?.amt ?? 0),
                                    account.accountBalance?.currentBalance?.curCode
                                ),
                                cur: valueWraper.getCurrency(account.accountBalance?.currentBalance?.curCode)
                            }

                            let previousBalance = {
                                amt: valueWraper.divideByExponents(
                                    account.accountBalance?.closingBalance != null ? account.accountBalance?.closingBalance?.amt : 0,
                                    account.accountBalance?.closingBalance?.curCode
                                ),
                                cur: valueWraper.getCurrency(account.accountBalance?.closingBalance?.curCode),
                                cr: false
                            }

                            previousBalance.cr = valueWraper.getCurrentBalance(previousBalance.amt);

                            let cardLimit = {
                                amt: valueWraper.divideByExponents(
                                    account.accountBalance?.purchaseAvailable?.amt,
                                    account.accountBalance?.purchaseAvailable?.curCode,
                                ),
                                cur: valueWraper.getCurrency(account.accountBalance?.purchaseAvailable?.curCode)
                            }

                            let availableCredit = {
                                amt: valueWraper.getAvailableCredit(productCode, productType, cardLimit),
                                cur: valueWraper.getCurrency(account.accountBalance?.purchaseAvailable?.curCode)
                            }

                            let currentBalance = {
                                amt: valueWraper.divideByExponents(
                                    account.accountBalance.currentBalance.amt + account?.accountBalance?.closingBalance.amt,
                                    account.accountBalance?.currentBalance?.curCode
                                ),
                                cur: valueWraper.getCurrency(account.accountBalance?.currentBalance?.curCode),
                                cr: false
                            };

                            currentBalance.cr = valueWraper.getCurrentBalance(currentBalance.amt);

                            // console.log('newBalanacenewBalanace', currentBalance.amt);

                            let amountDue = {
                                amt: valueWraper.divideByExponents(account.accountBalance.minimumDue.amt, account.accountBalance?.minimumDue?.curCode),
                                cur: valueWraper.getCurrency(account.accountBalance?.minimumDue?.curCode)
                            }

                            let isPrimary = valueWraper.getPrimaryCard(card.cardDetail.cardDemog.keyValues, productType, productCode, productName, accountCreditRiskLimit);

                            let isSupplementaryCard = valueWraper.isSupplementaryCard(cardStgeneral);
                            let canChangeLimit = valueWraper.canChangeLimit(isPrimary, productCode, productType, accountStgeneral, cardActivationStatus.isActive);

                            console.log('mRAccountStatus', mRAccountStatus);
                            console.log('mrAccountNo', mrAccountNo);

                            let mRParticipationFlag = valueWraper.getMRParticipationFlag(
                                account.accountBalance?.keyValues ?? [],
                            );

                            let memebershipCard = {
                                isSupplementaryCard: isSupplementaryCard,
                                productCode: productCode,
                                productType: productType,
                                mRAccountStatus: mRAccountStatus,
                                mrAccountNo: mrAccountNo,
                                mRParticipationFlag
                            }
                            let showMembershipPoints = valueWraper.getMembershipPointsVisibility(memebershipCard);

                            let data = {
                                card: card.cardDetail,
                                loyaltyAccountNbr: num,
                                expiry: valueWraper.getExpiry(card.cardDetail.cardDemog.keyValues),
                                isPrimary: isPrimary,
                                isActive: cardActivationStatus.isActive,
                                isEnableForCall: cardActivationStatus.isEnableForCall,
                                isSusByUser: cardActivationStatus.isSusByUser,
                                cardName: productName,
                                cardNameAr: productNameAr,
                                primeProductName: primeProductName,
                                accountProductType: productType,

                                currentBalance: currentBalance,
                                amountDue: amountDue,

                                creditLimit: creditLimit,
                                accountCreditRiskLimit: accountCreditRiskLimit,
                                previousBalance: previousBalance,

                                totalRecentPayments: totalRecentPayments,
                                totalRecentsTransaction: totalRecentsTransaction,

                                availableCredit: availableCredit,

                                sadadNumber: sadadNumber,
                                nextDueDate: moment(account.accountBalance.nextDueDate).format('DD MMM yyyy'),
                                dueOn: moment(account.accountBalance.nextDueDate).format('DD-MM-yyyy'),
                                accountNo: account.accountBalance?.cmsAccountId,
                                image: image,
                                Last4Digits: last4Digits,
                                primeCardSerno: valueWraper.getCardSerial(card.cardDetail.cardDemog.keyValues),
                                serialNo: account.accountBalance.cmsAccountId,
                                cardLimit: cardLimit,
                                // serialNo: valueWraper.getSerial(card.cardDetail.cardCustomFields),accountModel.accountBalance?.cmsAccountId
                                product: card.cardDetail.cardDemog.catPIProduct,
                                productCodeTemp: valueWraper.getAccountProductCode(account.accountBalance.keyValues),
                                cardMemberName: card.cardDetail.cardDemog.embossedName,
                                ProductCode: productCode,
                                cardStgeneral: card.cardDetail.cardDemog.statusCode + card.cardDetail.cardDemog.statusReason,
                                isSupplementaryCard: isSupplementaryCard,
                                canChangeLimit: canChangeLimit,

                                mRAccountStatus: mRAccountStatus,
                                mrAccountNo: mrAccountNo,
                                mRParticipationFlag: mRParticipationFlag,
                                showMembershipPoints: showMembershipPoints
                            }

                            if (isPrimary)
                            {
                                if (accountStgeneral != CardStatusGeneral.statusCode.CLSC &&
                                    accountStgeneral != CardStatusGeneral.statusCode.CLSP &&
                                    accountStgeneral != CardStatusGeneral.statusCode.CLSB)
                                {
                                    this.primaryCards.push(data)
                                }

                            }

                            tempCards.push(data);

                            if (cardActivationStatus.isActive)
                            {
                                this.isActive.push(data)
                            }
                            else
                            {
                                this.inActive.push(data)
                            }

                            this.allCards.push(data)
                        })

                        if (account.card.length > 0)
                        {
                            this.accountCards.push(tempCards);
                        }
                    })
                    let lang = localStorage.getItem('language');
                    if (lang)
                    {
                        if (lang == 'ar')
                        {
                            this.options.direction = 'rtl';
                        }
                        else
                        {
                            this.options.direction = 'ltr'
                        }
                    }
                    setTimeout(() =>
                    {
                        this.loadCards = true;
                    }, 1000);

                    this.cardDetails = this.primaryCards[0];

                    store.set('cardStore/allCards', this.allCards);
                    store.set('cardStore/primaryCards', this.primaryCards);
                    store.set('cardStore/activeCards', this.isActive);
                    store.set('cardStore/inActiveCards', this.inActive);
                    store.set('cardStore/accountCards', this.accountCards);

                    store.set('card/details', this.primaryCards[0]);

                    this.membershipRewardPoints(0);

                    this.loaded = true;
                }
                else
                {
                    localStorage.removeItem('refreshToken')
                    store.set('requestKeys/accessToken', null)
                    router.push('/' + this.selectedLanguage + '/login')
                }
            })
        },

        membershipRewardPoints(index)
        {
            // let obj = {
            //     idSeed: sharedServices.getIdSeed(),
            //     sysId: new DeviceUUID().get(),
            //     messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
            //     sysAuth: store.get('requestKeys/accessToken'),
            //     correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
            //     requestorID: localStorage.getItem('userName'),
            //     loyaltyAccountNumber: this.primaryCards[index].loyaltyAccountNbr
            // }
            if (this.primaryCards[index].loyaltyAccountNbr == 0)
            {
                return;
            }

            homeServices.getSummaryMR(store.get('requestKeys/userName'), this.primaryCards[index].loyaltyAccountNbr).then(resp => // username from store
            {
                // console.log(resp);
                this.membershipRewards = resp?.data?.availableBalance;
                this.refreshPoints++;
            })
            // soapServices.soapAPI(obj).then(soapResp =>
            // {
            //     this.membershipRewards = soapResp?.GetSummaryMRResponse?.GetSummaryMRResult?.Resp?.Body?.getSummaryResponseType?.summaryType?.availableBalance;
            // })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.carousel-3d-slide {
    background: transparent !important;
    border: 0 !important;
}

.circle {
    height: 16px;
    margin-top: 6px;
}

[dir="rtl"] {
    .warning2 {
        background: transparent !important;
    }
}

::v-deep .splide__slide {
    box-sizing: border-box;
    list-style-type: none !important;
    margin: 0;
    flex-shrink: 0;
    width: 200px !important;
}

::v-deep .splide__pagination__page {
    background: #D9D9D9 !important;
}

::v-deep .splide__pagination__page.is-active {
    transform: scale(1.1) !important;
    background: #A4A4A4 !important;
}

::v-deep .v-application ul,
.v-application ol {
    padding-left: 0px !important;
}

.alert {
    height: 37px;
}

@media screen and (max-width: 481px) {
    .card-balance {
        padding: 12px 4px !important;
    }

}
</style>
